.App {
    text-align: center;
    max-height: 100vh;
    max-width: 100vw;
}

.App-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: white;
}

.ant-btn-default {
    color: white;
    background-color: transparent;
    border: 2px solid;
    border-color: white;
}

.ant-btn-default:hover {
    color: white !important;
    background-color: transparent;
    border: 2px solid;
    border-color: pink !important;
}
