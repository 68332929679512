.Blog-header {
    color: white;
    font-size: 32px;
    margin: 10px;
}

.Blog-subheader {
    color: white;
    font-size: 26px;
    margin: 5px 10px;
}

.Blog-content {
    color: white;
    font-size: 18px;
    margin: 5px 10px;
}

.Blog-link,
.Blog-link:hover,
.Blog-link:visited,
.Blog-link:focus {
    color: pink;
    text-decoration: underline;
    text-decoration-color: pink;
}

.Blog-link:visited {
    color: lightgray;
    text-decoration-color: lightgray;
}

.Blog-link:hover {
    color: white;
    text-decoration-color: white;
}

.Blog-nav {
    width: 20%;
    height: 100%;
    padding: 1.25vh;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 24px;
}

.Blog-nav:hover {
    background-color: white;
    color: CornflowerBlue;
    cursor: pointer;
}

.Blog-nav-active {
    width: 20%;
    height: 100%;
    padding: 1.25vh;
    text-align: center;
    display: inline-block;
    font-size: 24px;
    background-color: white;
    color: CornflowerBlue;
    font-weight: bold;
    background-color: 'rgba(0,0,0,0.2)';
    cursor: default;
}

.container {
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin: 45px auto;
    color: white;
    padding: 40px 40px;
}

.Block {
    display: flex;
    width: 80%;
}

.row {
    display: flex;
    flex-direction: row;
    margin: auto 8%;
    padding-bottom: 28px;
}

.Half-block {
    width: 50%;
    margin: 0% 2%;
}

.Block-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
}

.Block-content {
    font-size: 16px;
    margin-bottom: 12px;
}
